import React, { useContext } from "react";
import { graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import { Layout, EventForm, Seo } from "../../components";
import ProductContext from "../../context/ProductContext";
import { Container } from "reactstrap";

//Exporting here allows Gatsby to inject this into props.data!
export const query = graphql`
	# query ProductQuery($shopifyId: String) {
	query EventQuery($shopifyId: String) {
		shopifyProduct(shopifyId: { eq: $shopifyId }) {
			# bringing in the fragment query we created in the fragments.js file
			...ShopifyProductFields
		}
	}
`;

export default function EventTemplate({ data: { shopifyProduct } }) {
	//Filtering out the event matching addOns
	const { products } = useContext(ProductContext);
	const addOns = products.filter((product) => {
		return (
			product.productType === "Add Ons" &&
			product.tags[0] === shopifyProduct.tags[0]
		);
	})[0];

	let imageQuery = {};

	if (shopifyProduct.images[0]) {
		imageQuery = getImage(shopifyProduct.images[0].localFile);
	}

	return (
		<Layout product={true}>
			<Seo
				title={shopifyProduct.title}
				description={shopifyProduct.description}
			/>
			<Container>
				<EventForm
					shopifyProduct={shopifyProduct}
					addOns={addOns}
					imageQuery={imageQuery}
				/>
			</Container>
		</Layout>
	);
}
